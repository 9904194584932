<template>
    <div>
       <b-overlay :show="loading" :opacity="0.6"> 
       <b-row>
             <b-col lg="10" xl="8" offset-xl="2" offset-lg="1"> 
                    <h1 class="mt-2 mb-4">{{trans('add-ort-schema',207)}}</h1>

                    <ValidationObserver ref="form">

                        <b-row class="match-height mb-3">
                            <b-col md="12" class="pr-md-2 mb-sm-3 mb-md-0">
                                <!--section 1-->
                                <b-card class="mb-3" >
                                    <div class="app-card-profile-title mb-3">{{trans('duration-cao',207)}}</div>
                                    <b-row>
                                        <b-col cols="6">
                                            <app-week v-model="date_from" :disabledDatesRange="disabledDatesRange" validatorRules="required" validatorName="Startdatum" :label="trans('date-from',182)"/>
                                        </b-col>
                                        <b-col cols="6">
                                            <app-week v-model="date_to" :label="trans('date-to',182)" :disabledDatesRange="disabledDatesRangeTo"/>
                                        </b-col>
                                    </b-row>
                                </b-card>

                                <!--section 2-->
                                <b-card class="mb-3" >
                                    <div class="app-card-profile-title mb-3">{{trans('settings-ort-schedule',167)}}</div>
                                    <b-row>
                                        <b-col xl="6" lg="12">
                                           <span class="app-day-label"> {{trans('monday',182)}} </span>
                                           <b-row class="mt-2 app-local-headers-row">
                                                <b-col cols="4" class="app-local-headers">
                                                    <span class="app-input-top-label"> {{trans('settings-from',207)}} </span>
                                                </b-col>
                                                <b-col cols="4" class="app-local-headers">
                                                    <span class="app-input-top-label"> {{trans('settings-to',207)}} </span>
                                                </b-col>
                                                <b-col cols="3" class="app-local-headers ml-2">
                                                    <span class="app-input-top-label"> {{trans('cw-percentage',194)}} </span>
                                                </b-col>
                                           </b-row>
                                           <b-row v-for="(item, index) in ort[1]" :key="index">
                                                <app-row-left-label :slots="[4, 4, 3, 1]" class="pl-3 pr-4" style="width:100%">
                                                    <template v-slot:col-1>
                                                        <app-time v-model="item.time_from" :currentError="firstLoad == false && cValidateValueMa == null" :acceptedTime="index != 0 ? (acceptedTimeMaan[index-1] ? acceptedTimeMaan[index-1] : acceptedTimeMaan[acceptedTimeMaan.length-1]) : ''" @input="acceptedTime(1)" :key="refreshTime[1]" @close="refreshTimes(1)" @change="refreshTimes(1)" :validatorCustomMessage="{ 'disable-time': trans('invalid-time',199) }"/>
                                                    </template>
                                                    <template v-slot:col-2>
                                                        <app-time v-model="item.time_to" :currentError="firstLoad == false && cValidateValueMa == null" :acceptedTime="(index == 0 && item.time_to == '00:00' && item.time_from == '00:00') || (index != 0 && item.time_to == '00:00') ? ' 0:00' : (item.time_from ? '>'+ item.time_from : (acceptedTimeMaan[index-1] ? acceptedTimeMaan[index-1] : acceptedTimeMaan[acceptedTimeMaan.length-1])) " @input="acceptedTime(1)" :key="refreshTime[1]" @close="refreshTimes(1)" @change="refreshTimes(1)" :validatorCustomMessage="{ 'disable-time': trans('invalid-time',199) }"/>
                                                    </template>
                                                    <template v-slot:col-3>
                                                        <app-select v-model="item.percent_value" :args.sync="percent_parameters" :currentError="firstLoad == false && cValidateValueMa == null" type="getCaoSchedulePercentages" @loaded="getPercentValues" v-if="!percent_values"/>
                                                        <app-select v-model="item.percent_value" :options="percent_values" :currentError="firstLoad == false && cValidateValueMa == null" v-else/>
                                                    </template>
                                                    <template v-slot:col-4>
                                                        <app-button type="secondary-grey" class="ml-1 app-local-button p-2" :class="{ 'app-local-button-disabled':index == 0 && ort[1].length == 1 }" @click="remove(1, index)" :disabled="index == 0 && ort[1].length == 1">
                                                            <b-icon icon="dash" class="list-button-icon"></b-icon>
                                                        </app-button>
                                                    </template>
                                                </app-row-left-label>
                                           </b-row>
                                    
                                           <validation-provider ref="provider" v-slot="{ errors}" rules="required">
                                                <input type="hidden" v-model="cValidateValueMa" />
                                                <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error" v-overflow-tooltip>{{trans('cao-one-rate-required',207)}}</small> 
                                            </validation-provider>
                                           <b-col end class='d-flex justify-content-end pl-0' >
                                                <app-button type="cancel" @click="add(1)" class="p-0 app-local-text" :class="{ 'app-local-btn-cancel': !ort[1][ort[1].length -1].time_from  || !ort[1][ort[1].length -1].time_to || ort[1][ort[1].length -1].time_to == '00:00' || ort[1][ort[1].length -1].time_to == '24:00' }">
                                                    <b-icon icon="plus-circle" class="mr-1"/> <span> {{trans('add',175)}} </span>
                                                </app-button>
                                            </b-col>
                                        </b-col>

                                        <b-col xl="6" lg="12">
                                            <span class="app-day-label"> {{trans('tuesday',182)}} </span>
                                            <b-row class="mt-2 app-local-headers-row">
                                                <b-col cols="4" class="app-local-headers">
                                                    <span class="app-input-top-label"> {{trans('settings-from',207)}} </span>
                                                </b-col>
                                                <b-col cols="4" class="app-local-headers">
                                                    <span class="app-input-top-label"> {{trans('settings-to',207)}} </span>
                                                </b-col>
                                                <b-col cols="3" class="app-local-headers ml-2">
                                                    <span class="app-input-top-label"> {{trans('cw-percentage',194)}} </span>
                                                </b-col>
                                           </b-row>
                                           <b-row v-for="(item, index) in ort[2]" :key="index">
                                                <app-row-left-label :slots="[4, 4, 3, 1]" class="pl-3 pr-4" style="width:100%">
                                                    <template v-slot:col-1>
                                                        <app-time v-model="item.time_from" :currentError="firstLoad == false && cValidateValueDi == null" :acceptedTime="index != 0 ? (acceptedTimeDin[index-1] ? acceptedTimeDin[index-1] : acceptedTimeDin[acceptedTimeDin.length-1]) : ''" @input="acceptedTime(2)" :key="refreshTime[2]" @close="refreshTimes(2)" @change="refreshTimes(2)" :validatorCustomMessage="{ 'disable-time': trans('invalid-time',199) }"/>
                                                    </template>
                                                    <template v-slot:col-2>
                                                        <app-time v-model="item.time_to" :currentError="firstLoad == false && cValidateValueDi == null" :acceptedTime="(index == 0 && item.time_to == '00:00' && item.time_from == '00:00') || (index != 0 && item.time_to == '00:00') ? ' 0:00' : (item.time_from ? '>'+ item.time_from : (acceptedTimeDin[index-1] ? acceptedTimeDin[index-1] : acceptedTimeDin[acceptedTimeDin.length-1])) " @input="acceptedTime(2)" :key="refreshTime[2]" @close="refreshTimes(2)" @change="refreshTimes(2)" :validatorCustomMessage="{ 'disable-time': trans('invalid-time',199) }"/>
                                                    </template>
                                                    <template v-slot:col-3>
                                                        <app-select v-model="item.percent_value" :args.sync="percent_parameters" :currentError="firstLoad == false && cValidateValueDi == null" type="getCaoSchedulePercentages" @loaded="getPercentValues" v-if="!percent_values"/>
                                                        <app-select v-model="item.percent_value" :options="percent_values" :currentError="firstLoad == false && cValidateValueDi == null" v-else/>                                                    </template>
                                                    <template v-slot:col-4>
                                                        <app-button type="secondary-grey" class="ml-1 app-local-button p-2" :class="{ 'app-local-button-disabled':index == 0 && ort[2].length == 1 }" @click="remove(2, index)" :disabled="index == 0 && ort[2].length == 1">
                                                            <b-icon icon="dash" class="list-button-icon"></b-icon>
                                                        </app-button>
                                                    </template>
                                                </app-row-left-label>
                                           </b-row>

                                           <validation-provider ref="provider" v-slot="{ errors}" rules="required">
                                                <input type="hidden" v-model="cValidateValueDi" />
                                                <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error" v-overflow-tooltip>{{trans('cao-one-rate-required',207)}}</small> 
                                            </validation-provider>
                                           <b-col end class='d-flex justify-content-end pl-0' >
                                                <app-button type="cancel" @click="add(2)" class="p-0 app-local-text" :class="{ 'app-local-btn-cancel': !ort[2][ort[2].length -1].time_from  || !ort[2][ort[2].length -1].time_to || ort[2][ort[2].length -1].time_to == '00:00' || ort[2][ort[2].length -1].time_to == '24:00' }">
                                                    <b-icon icon="plus-circle" class="mr-1"/> <span> {{trans('add',175)}} </span>
                                                </app-button>                                            
                                            </b-col>
                                        </b-col>
                                        <hr class="app-local-line" /> 
                                    </b-row>
                                    <b-row>
                                        <b-col xl="6" lg="12">
                                            <span class="app-day-label"> {{trans('wednesday',182)}} </span>
                                           <b-row class="mt-2 app-local-headers-row">
                                                <b-col cols="4" class="app-local-headers">
                                                    <span class="app-input-top-label"> {{trans('settings-from',207)}} </span>
                                                </b-col>
                                                <b-col cols="4" class="app-local-headers">
                                                    <span class="app-input-top-label"> {{trans('settings-to',207)}} </span>
                                                </b-col>
                                                <b-col cols="3" class="app-local-headers ml-2">
                                                    <span class="app-input-top-label"> {{trans('cw-percentage',194)}} </span>
                                                </b-col>
                                           </b-row>
                                           <b-row v-for="(item, index) in ort[3]" :key="index">
                                                <app-row-left-label :slots="[4, 4, 3, 1]" class="pl-3 pr-4" style="width:100%">
                                                    <template v-slot:col-1>
                                                        <app-time v-model="item.time_from" :currentError="firstLoad == false && cValidateValueWo == null" :acceptedTime="index != 0 ? (acceptedTimeWoen[index-1] ? acceptedTimeWoen[index-1] : acceptedTimeWoen[acceptedTimeWoen.length-1]) : ''" @input="acceptedTime(3)" :key="refreshTime[3]" @close="refreshTimes(3)" @change="refreshTimes(3)" :validatorCustomMessage="{ 'disable-time': trans('invalid-time',199) }"/>
                                                    </template>
                                                    <template v-slot:col-2>
                                                        <app-time v-model="item.time_to" :currentError="firstLoad == false && cValidateValueWo == null" :acceptedTime="(index == 0 && item.time_to == '00:00' && item.time_from == '00:00') || (index != 0 && item.time_to == '00:00') ? ' 0:00' : (item.time_from ? '>'+ item.time_from : (acceptedTimeWoen[index-1] ? acceptedTimeWoen[index-1] : acceptedTimeWoen[acceptedTimeWoen.length-1])) " @input="acceptedTime(3)" :key="refreshTime[3]" @close="refreshTimes(3)" @change="refreshTimes(3)" :validatorCustomMessage="{ 'disable-time': trans('invalid-time',199) }"/>
                                                    </template>
                                                    <template v-slot:col-3>
                                                        <app-select v-model="item.percent_value" :args.sync="percent_parameters" :currentError="firstLoad == false && cValidateValueWo == null" type="getCaoSchedulePercentages" @loaded="getPercentValues" v-if="!percent_values"/>
                                                        <app-select v-model="item.percent_value" :options="percent_values" :currentError="firstLoad == false && cValidateValueWo == null" v-else/>                                                    </template>
                                                    <template v-slot:col-4>
                                                        <app-button type="secondary-grey" class="ml-1 app-local-button p-2" :class="{ 'app-local-button-disabled':index == 0 && ort[3].length == 1 }" @click="remove(3, index)" :disabled="index == 0 && ort[3].length == 1">
                                                            <b-icon icon="dash" class="list-button-icon"></b-icon>
                                                        </app-button>
                                                    </template>
                                                </app-row-left-label>
                                           </b-row>

                                           <validation-provider ref="provider" v-slot="{ errors}" rules="required">
                                                <input type="hidden" v-model="cValidateValueWo" />
                                                <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error" v-overflow-tooltip>{{trans('cao-one-rate-required',207)}}</small> 
                                            </validation-provider>
                                           <b-col end class='d-flex justify-content-end pl-0' >
                                                <app-button type="cancel" @click="add(3)" class="p-0 app-local-text" :class="{ 'app-local-btn-cancel': !ort[3][ort[3].length -1].time_from  || !ort[3][ort[3].length -1].time_to || ort[3][ort[3].length -1].time_to == '00:00' || ort[3][ort[3].length -1].time_to == '24:00' }">
                                                    <b-icon icon="plus-circle" class="mr-1"/> <span> {{trans('add',175)}} </span>
                                                </app-button>                                            
                                            </b-col>
                                        </b-col>

                                        <b-col xl="6" lg="12">
                                            <span class="app-day-label"> {{trans('thursday',182)}} </span>
                                           <b-row class="mt-2 app-local-headers-row">
                                                <b-col cols="4" class="app-local-headers">
                                                    <span class="app-input-top-label"> {{trans('settings-from',207)}} </span>
                                                </b-col>
                                                <b-col cols="4" class="app-local-headers">
                                                    <span class="app-input-top-label"> {{trans('settings-to',207)}} </span>
                                                </b-col>
                                                <b-col cols="3" class="app-local-headers ml-2">
                                                    <span class="app-input-top-label"> {{trans('cw-percentage',194)}} </span>
                                                </b-col>
                                           </b-row>
                                           <b-row v-for="(item, index) in ort[4]" :key="index">
                                                <app-row-left-label :slots="[4, 4, 3, 1]" class="pl-3 pr-4" style="width:100%">
                                                    <template v-slot:col-1>
                                                        <app-time v-model="item.time_from" :currentError="firstLoad == false && cValidateValueDo == null" :acceptedTime="index != 0 ? (acceptedTimeDond[index-1] ? acceptedTimeDond[index-1] : acceptedTimeDond[acceptedTimeDond.length-1]) : ''" @input="acceptedTime(4)" :key="refreshTime[4]" @close="refreshTimes(4)" @change="refreshTimes(4)" :validatorCustomMessage="{ 'disable-time': trans('invalid-time',199) }"/>
                                                    </template>
                                                    <template v-slot:col-2>
                                                        <app-time v-model="item.time_to" :currentError="firstLoad == false && cValidateValueDo == null" :acceptedTime="(index == 0 && item.time_to == '00:00' && item.time_from == '00:00') || (index != 0 && item.time_to == '00:00') ? ' 0:00' : (item.time_from ? '>'+ item.time_from : (acceptedTimeDond[index-1] ? acceptedTimeDond[index-1] : acceptedTimeDond[acceptedTimeDond.length-1])) " @input="acceptedTime(4)" :key="refreshTime[4]" @close="refreshTimes(4)" @change="refreshTimes(4)" :validatorCustomMessage="{ 'disable-time': trans('invalid-time',199) }"/>
                                                    </template>
                                                    <template v-slot:col-3>
                                                        <app-select v-model="item.percent_value" :args.sync="percent_parameters" :currentError="firstLoad == false && cValidateValueDo == null" type="getCaoSchedulePercentages" @loaded="getPercentValues" v-if="!percent_values"/>
                                                        <app-select v-model="item.percent_value" :options="percent_values" :currentError="firstLoad == false && cValidateValueDo == null" v-else/>                                                    </template>
                                                    <template v-slot:col-4>
                                                        <app-button type="secondary-grey" class="ml-1 app-local-button p-2" :class="{ 'app-local-button-disabled':index == 0 && ort[4].length == 1 }" @click="remove(4, index)" :disabled="index == 0 && ort[4].length == 1">
                                                            <b-icon icon="dash" class="list-button-icon"></b-icon>
                                                        </app-button>
                                                    </template>
                                                </app-row-left-label>
                                           </b-row>

                                           <validation-provider ref="provider" v-slot="{ errors}" rules="required">
                                                <input type="hidden" v-model="cValidateValueDo" />
                                                <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error" v-overflow-tooltip>{{trans('cao-one-rate-required',207)}}</small> 
                                            </validation-provider>
                                           <b-col end class='d-flex justify-content-end pl-0' >
                                                <app-button type="cancel" @click="add(4)" class="p-0 app-local-text" :class="{ 'app-local-btn-cancel': !ort[4][ort[4].length -1].time_from  || !ort[4][ort[4].length -1].time_to || ort[4][ort[4].length -1].time_to == '00:00' || ort[4][ort[4].length -1].time_to == '24:00' }">
                                                    <b-icon icon="plus-circle" class="mr-1"/> <span> {{trans('add',175)}} </span>
                                                </app-button>                                            
                                            </b-col>
                                        </b-col>
                                        <hr class="app-local-line" /> 
                                    </b-row>
                                    <b-row>
                                        <b-col xl="6" lg="12">
                                            <span class="app-day-label"> {{trans('friday',182)}} </span>
                                           <b-row class="mt-2 app-local-headers-row">
                                                <b-col cols="4" class="app-local-headers">
                                                    <span class="app-input-top-label"> {{trans('settings-from',207)}} </span>
                                                </b-col>
                                                <b-col cols="4" class="app-local-headers">
                                                    <span class="app-input-top-label"> {{trans('settings-to',207)}} </span>
                                                </b-col>
                                                <b-col cols="3" class="app-local-headers ml-2">
                                                    <span class="app-input-top-label"> {{trans('cw-percentage',194)}} </span>
                                                </b-col>
                                           </b-row>
                                           <b-row v-for="(item, index) in ort[5]" :key="index">
                                                <app-row-left-label :slots="[4, 4, 3, 1]" class="pl-3 pr-4" style="width:100%">
                                                    <template v-slot:col-1>
                                                        <app-time v-model="item.time_from" :currentError="firstLoad == false && cValidateValueVr == null" :acceptedTime="index != 0 ? (acceptedTimeVrij[index-1] ? acceptedTimeVrij[index-1] : acceptedTimeVrij[acceptedTimeVrij.length-1]) : ''" @input="acceptedTime(5)" :key="refreshTime[5]" @close="refreshTimes(5)" @change="refreshTimes(5)" :validatorCustomMessage="{ 'disable-time': trans('invalid-time',199) }"/>
                                                    </template>
                                                    <template v-slot:col-2>
                                                        <app-time v-model="item.time_to" :currentError="firstLoad == false && cValidateValueVr == null" :acceptedTime="(index == 0 && item.time_to == '00:00' && item.time_from == '00:00') || (index != 0 && item.time_to == '00:00') ? ' 0:00' : (item.time_from ? '>'+ item.time_from : (acceptedTimeVrij[index-1] ? acceptedTimeVrij[index-1] : acceptedTimeVrij[acceptedTimeVrij.length-1])) " @input="acceptedTime(5)" :key="refreshTime[5]" @close="refreshTimes(5)" @change="refreshTimes(5)" :validatorCustomMessage="{ 'disable-time': trans('invalid-time',199) }"/>
                                                    </template>
                                                    <template v-slot:col-3>
                                                        <app-select v-model="item.percent_value" :args.sync="percent_parameters" :currentError="firstLoad == false && cValidateValueVr == null" type="getCaoSchedulePercentages" @loaded="getPercentValues" v-if="!percent_values"/>
                                                        <app-select v-model="item.percent_value" :options="percent_values" :currentError="firstLoad == false && cValidateValueVr == null" v-else/>                                                    </template>
                                                    <template v-slot:col-4>
                                                        <app-button type="secondary-grey" class="ml-1 app-local-button p-2" :class="{ 'app-local-button-disabled':index == 0 && ort[5].length == 1 }" @click="remove(5, index)" :disabled="index == 0 && ort[5].length == 1">
                                                            <b-icon icon="dash" class="list-button-icon"></b-icon>
                                                        </app-button>
                                                    </template>
                                                </app-row-left-label>
                                           </b-row>

                                           <validation-provider ref="provider" v-slot="{ errors}" rules="required">
                                                <input type="hidden" v-model="cValidateValueVr" />
                                                <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error" v-overflow-tooltip>{{trans('cao-one-rate-required',207)}}</small> 
                                            </validation-provider>
                                           <b-col end class='d-flex justify-content-end pl-0' >
                                                <app-button type="cancel" @click="add(5)" class="p-0 app-local-text" :class="{ 'app-local-btn-cancel': !ort[5][ort[5].length -1].time_from  || !ort[5][ort[5].length -1].time_to || ort[5][ort[5].length -1].time_to == '00:00' || ort[5][ort[5].length -1].time_to == '24:00' }">
                                                    <b-icon icon="plus-circle" class="mr-1"/> <span> {{trans('add',175)}} </span>
                                                </app-button>                                            
                                            </b-col>
                                        </b-col>

                                        <b-col xl="6" lg="12">
                                            <span class="app-day-label"> {{trans('saturday',182)}} </span>
                                           <b-row class="mt-2 app-local-headers-row">
                                                <b-col cols="4" class="app-local-headers">
                                                    <span class="app-input-top-label"> {{trans('settings-from',207)}} </span>
                                                </b-col>
                                                <b-col cols="4" class="app-local-headers">
                                                    <span class="app-input-top-label"> {{trans('settings-to',207)}} </span>
                                                </b-col>
                                                <b-col cols="3" class="app-local-headers ml-2">
                                                    <span class="app-input-top-label"> {{trans('cw-percentage',194)}} </span>
                                                </b-col>
                                           </b-row>
                                           <b-row v-for="(item, index) in ort[6]" :key="index">
                                                <app-row-left-label :slots="[4, 4, 3, 1]" class="pl-3 pr-4" style="width:100%">
                                                    <template v-slot:col-1>
                                                        <app-time v-model="item.time_from" :currentError="firstLoad == false && cValidateValueZa == null" :acceptedTime="index != 0 ? (acceptedTimeZat[index-1] ? acceptedTimeZat[index-1] : acceptedTimeZat[acceptedTimeZat.length-1]) : ''" @input="acceptedTime(6)" :key="refreshTime[6]" @close="refreshTimes(6)" @change="refreshTimes(6)" :validatorCustomMessage="{ 'disable-time': trans('invalid-time',199) }"/>
                                                    </template>
                                                    <template v-slot:col-2>
                                                        <app-time v-model="item.time_to" :currentError="firstLoad == false && cValidateValueZa == null" :acceptedTime="(index == 0 && item.time_to == '00:00' && item.time_from == '00:00') || (index != 0 && item.time_to == '00:00') ? ' 0:00' : (item.time_from ? '>'+ item.time_from : (acceptedTimeZat[index-1] ? acceptedTimeZat[index-1] : acceptedTimeZat[acceptedTimeZat.length-1])) " @input="acceptedTime(6)" :key="refreshTime[6]" @close="refreshTimes(6)" @change="refreshTimes(6)" :validatorCustomMessage="{ 'disable-time': trans('invalid-time',199) }"/>
                                                    </template>
                                                    <template v-slot:col-3>
                                                        <app-select v-model="item.percent_value" :args.sync="percent_parameters" :currentError="firstLoad == false && cValidateValueZa == null" type="getCaoSchedulePercentages" @loaded="getPercentValues" v-if="!percent_values"/>
                                                        <app-select v-model="item.percent_value" :options="percent_values" :currentError="firstLoad == false && cValidateValueZa == null" v-else/>                                                    </template>
                                                    <template v-slot:col-4>
                                                        <app-button type="secondary-grey" class="ml-1 app-local-button p-2" :class="{ 'app-local-button-disabled':index == 0 && ort[6].length == 1 }" @click="remove(6, index)" :disabled="index == 0 && ort[6].length == 1">
                                                            <b-icon icon="dash" class="list-button-icon"></b-icon>
                                                        </app-button>
                                                    </template>
                                                </app-row-left-label>
                                           </b-row>

                                           <validation-provider ref="provider" v-slot="{ errors}" rules="required">
                                                <input type="hidden" v-model="cValidateValueZa" />
                                                <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error" v-overflow-tooltip>{{trans('cao-one-rate-required',207)}}</small> 
                                            </validation-provider>
                                           <b-col end class='d-flex justify-content-end pl-0' >
                                                <app-button type="cancel" @click="add(6)" class="p-0 app-local-text" :class="{ 'app-local-btn-cancel': !ort[6][ort[6].length -1].time_from  || !ort[6][ort[6].length -1].time_to || ort[6][ort[6].length -1].time_to == '00:00' || ort[6][ort[6].length -1].time_to == '24:00' }">
                                                    <b-icon icon="plus-circle" class="mr-1"/> <span> {{trans('add',175)}} </span>
                                                </app-button>                                            
                                            </b-col>
                                        </b-col>
                                        <hr class="app-local-line" /> 
                                    </b-row>
                                    <b-row>
                                        <b-col xl="6" lg="12">
                                            <span class="app-day-label"> {{trans('sunday',182)}} </span>
                                           <b-row class="mt-2 app-local-headers-row">
                                                <b-col cols="4" class="app-local-headers">
                                                    <span class="app-input-top-label"> {{trans('settings-from',207)}} </span>
                                                </b-col>
                                                <b-col cols="4" class="app-local-headers">
                                                    <span class="app-input-top-label"> {{trans('settings-to',207)}} </span>
                                                </b-col>
                                                <b-col cols="3" class="app-local-headers ml-2">
                                                    <span class="app-input-top-label"> {{trans('cw-percentage',194)}} </span>
                                                </b-col>
                                           </b-row>
                                           <b-row v-for="(item, index) in ort[7]" :key="index">
                                                <app-row-left-label :slots="[4, 4, 3, 1]" class="pl-3 pr-4" style="width:100%">
                                                    <template v-slot:col-1>
                                                        <app-time v-model="item.time_from" :currentError="firstLoad == false && cValidateValueZo == null" :acceptedTime="index != 0 ? (acceptedTimeZon[index-1] ? acceptedTimeZon[index-1] : acceptedTimeZon[acceptedTimeZon.length-1]) : ''" @input="acceptedTime(7)" :key="refreshTime[7]" @close="refreshTimes(7)" @change="refreshTimes(7)" :validatorCustomMessage="{ 'disable-time': trans('invalid-time',199) }"/>
                                                    </template>
                                                    <template v-slot:col-2>
                                                        <app-time v-model="item.time_to" :currentError="firstLoad == false && cValidateValueZo == null" :acceptedTime="(index == 0 && item.time_to == '00:00' && item.time_from == '00:00') || (index != 0 && item.time_to == '00:00') ? ' 0:00' : (item.time_from ? '>'+ item.time_from : (acceptedTimeZon[index-1] ? acceptedTimeZon[index-1] : acceptedTimeZon[acceptedTimeZon.length-1])) " @input="acceptedTime(7)" :key="refreshTime[7]" @close="refreshTimes(7)" @change="refreshTimes(7)" :validatorCustomMessage="{ 'disable-time': trans('invalid-time',199) }"/>
                                                    </template>
                                                    <template v-slot:col-3>
                                                        <app-select v-model="item.percent_value" :args.sync="percent_parameters" :currentError="firstLoad == false && cValidateValueZo == null" type="getCaoSchedulePercentages" @loaded="getPercentValues" v-if="!percent_values"/>
                                                        <app-select v-model="item.percent_value" :options="percent_values" :currentError="firstLoad == false && cValidateValueZo == null" v-else/>                                                    </template>
                                                    <template v-slot:col-4>
                                                        <app-button type="secondary-grey" class="ml-1 app-local-button p-2" :class="{ 'app-local-button-disabled':index == 0 && ort[7].length == 1 }" @click="remove(7, index)" :disabled="index == 0 && ort[7].length == 1">
                                                            <b-icon icon="dash" class="list-button-icon"></b-icon>
                                                        </app-button>
                                                    </template>
                                                </app-row-left-label>
                                           </b-row>

                                           <validation-provider ref="provider" v-slot="{ errors}" rules="required">
                                                <input type="hidden" v-model="cValidateValueZo" />
                                                <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error" v-overflow-tooltip>{{trans('cao-one-rate-required',207)}}</small> 
                                            </validation-provider>
                                           <b-col end class='d-flex justify-content-end pl-0' >
                                                <app-button type="cancel" @click="add(7)" class="p-0 app-local-text" :class="{ 'app-local-btn-cancel': !ort[7][ort[7].length -1].time_from  || !ort[7][ort[7].length -1].time_to || ort[7][ort[7].length -1].time_to == '00:00' || ort[7][ort[7].length -1].time_to == '24:00' }">
                                                    <b-icon icon="plus-circle" class="mr-1"/> <span> {{trans('add',175)}} </span>
                                                </app-button>                                            
                                            </b-col>
                                        </b-col>
                                        <b-col cols="6">
                                        </b-col>
                                    </b-row>
                                </b-card>

                                <!--section 3-->
                                <b-card class="mb-3" >
                                    <div class="app-card-profile-title mb-3">{{trans('cp-ort-caps',190)}}</div>
                                    <b-row>
                                        <b-col cols="6">
                                            <app-input v-model="ort_max_rate" :key="refresh" type="decimal_places_02" :validatorName="trans('settings-ort-max-rate', 167)" validatorRules="required" :label="trans('settings-ort-max-rate', 167)"/>
                                        </b-col>
                                        <b-col cols="6">
                                            <app-select v-model="ort_minimum_scale" :options="scale_options" :validatorName="trans('settings-min-scale', 167)" validatorRules="required" :label="trans('settings-min-scale', 167)"/>
                                        </b-col>
                                    </b-row>
                                </b-card>

                                <!--section 4-->
                                <b-card class="mb-3" >
                                    <div class="app-card-profile-title mb-3">{{trans('travel-costs',192)}}</div>
                                    <b-row>
                                        <b-col cols="6">
                                            <app-input v-model="cost_house_work" :label="trans('cw-home-work',194)" type="decimal_01"/>
                                        </b-col>
                                        <b-col cols="6">
                                            <app-input v-model="cost_work_work" :label="trans('cw-work-work',194)" type="decimal_01"/>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-col>
                        </b-row>

                    </ValidationObserver>

                    <div class="mt-4 mb-3" >
                        <app-button type="primary" :loading="loading" @click="addOrt()">
                            {{trans('add',175)}}
                        </app-button>
                    </div>
             </b-col>
       </b-row>
            <template #overlay>
                <div></div>
            </template>
        </b-overlay> 
    </div>
</template>
<script>

import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import moment from "moment";

export default {
    components: {

        ValidationObserver,
    },

    mounted() {
    },

    created(){
        this.getWizardData()
    },

    computed: {
        cValidateValueMa: {
            get(){
                var result = 0;
                this.ort[1].forEach(element => {
                    if(element.time_from != null && element.time_to != null && element.percent_value != null){
                        result++;
                    }
                });
                return result ? true : null;
            }
        },
        cValidateValueDi: {
            get(){
                var result = 0;
                this.ort[2].forEach(element => {
                    if(element.time_from != null && element.time_to != null && element.percent_value != null){
                        result++;
                    }
                });
                return result ? true : null;
            }
        },
        cValidateValueWo: {
            get(){
                var result = 0;
                this.ort[3].forEach(element => {
                    if(element.time_from != null && element.time_to != null && element.percent_value != null){
                        result++;
                    }
                });
                return result ? true : null;
            }
        },
        cValidateValueDo: {
            get(){
                var result = 0;
                this.ort[4].forEach(element => {
                    if(element.time_from != null && element.time_to != null && element.percent_value != null){
                        result++;
                    }
                });
                return result ? true : null;
            }
        },
        cValidateValueVr: {
            get(){
                var result = 0;
                this.ort[5].forEach(element => {
                    if(element.time_from != null && element.time_to != null && element.percent_value != null){
                        result++;
                    }
                });
                return result ? true : null;
            }
        },
        cValidateValueZa: {
            get(){
                var result = 0;
                this.ort[6].forEach(element => {
                    if(element.time_from != null && element.time_to != null && element.percent_value != null){
                        result++;
                    }
                });
                return result ? true : null;
            }
        },
        cValidateValueZo: {
            get(){
                var result = 0;
                this.ort[7].forEach(element => {
                    if(element.time_from != null && element.time_to != null && element.percent_value != null){
                        result++;
                    }
                });
                return result ? true : null;
            }
        },
    },

    data() {
        return {
            id_cao_data_main:null,
            firstLoad: true,
            cao_periods:[],
            loading: false,
            date_from: null,
            date_to: null,
            ort_max_rate: null,
            ort_minimum_scale: null,
            cost_house_work: null,
            cost_work_work: null,
            refresh:0,
            refreshTime:[0,0,0,0,0,0,0,0],
            disabledDatesRange: null,
            disabledDatesRangeTo: null,
            acceptedTimeMaan: [''],
            acceptedTimeDin: [''],
            acceptedTimeWoen: [''],
            acceptedTimeDond: [''],
            acceptedTimeVrij: [''],
            acceptedTimeZat: [''],
            acceptedTimeZon: [''],
            percent_parameters:{
                id_cao_data_main: null
            },
            percent_values:null,
            scale_options: [
                { value: 5, name: "5" },
                { value: 10, name: "10" },
                { value: 15, name: "15" },
                { value: 20, name: "20" },
                { value: 25, name: "25" },
                { value: 30, name: "30" },
                { value: 35, name: "35" },
                { value: 40, name: "40" },
                { value: 45, name: "45" },
                { value: 50, name: "50" },
                { value: 55, name: "55" },
                { value: 60, name: "60" },
            ],
            ort:{
                "1": [
                    {time_from:null, time_to:null, percent_value:null}                
                ],
                "2": [                    
                    {time_from:null,time_to:null,percent_value:null}
                ],
                "3": [                    
                    {time_from:null,time_to:null,percent_value:null}
                ],
                "4": [                    
                    {time_from:null,time_to:null,percent_value:null}
                ],
                "5": [                    
                    {time_from:null,time_to:null,percent_value:null}
                ],
                "6": [                    
                    {time_from:null,time_to:null,percent_value:null}
                ],
                "7": [                    
                    {time_from:null,time_to:null,percent_value:null}
                ],
            }
        };
    },

    watch:{
        date_from: {
            handler: function(val){
                if(val != null){
                    let date_from = moment().day("Monday").year(this.date_from.split('-')[0]).isoWeek(this.date_from.split('-')[1]).toDate();
                    this.disabledDatesRangeTo = '(date <= new Date('+moment(date_from)+'))';
                } 
            }
        },
    },

    methods: {
        addOrt() {
            this.firstLoad = false;
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    this.loading = true;

                    let date_to = null;
                    let date_from = moment(this.date_from.split('-')[0]).add(this.date_from.split('-')[1], 'weeks').startOf('isoweek').format('DD-MM-YYYY');

                    if(this.date_to){
                        date_to = moment(this.date_to.split('-')[0]).add(this.date_to.split('-')[1], 'weeks').startOf('isoweek').format('DD-MM-YYYY');
                    }
                    
                    for(let i=1;i<=7;i++){
                        this.ort[i].forEach(function(item, index, object) {
                            if(item.percent_value != null){
                                item.percent_value = item.percent_value.value;
                            }
                            if(item.percent_value == null || item.time_from == null || item.time_to == null){
                                object.splice(index, 1);
                            }
                        });
                    }

                    axios
                        .post("settings/sections/profile/schedule/addSchedule", {
                            schedule: JSON.stringify(this.ort),
                            date_from: date_from,
                            date_to: date_to,
                            ort_max_rate: this.ort_max_rate.replace(',','.'),
                            ort_minimum_scale: this.ort_minimum_scale.value,
                            cost_house_work: this.cost_house_work,
                            cost_work_work: this.cost_work_work,
                            id_cao_data_main: this.id_cao_data_main,
                        })
                        .then((res) => {
                            this.$router.push({ name: "sections-profile", params: { id_cao_data_main: res.data } });
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                    }
            })
        },

        getWizardData(){
            this.loading = true;
            axios
                .post("settings/sections/profile/schedule/getWizardData", {
                    id_cao_data_schedule: this.$route.params.id_cao_data_schedule,
                })
                .then((res) => {
                    this.cao_periods = res.data.cao_periods;
                    this.id_cao_data_main = res.data.schedule_data.id_cao_data_main;
                    this.ort_minimum_scale = {value: res.data.schedule_data.ort_minimum_scale, name: res.data.schedule_data.ort_minimum_scale};
                    this.cost_house_work = res.data.schedule_data.work_home_cost;
                    this.cost_work_work = res.data.schedule_data.work_work_cost;
                    this.ort_max_rate = res.data.schedule_data.ort_max_rate;
                    this.percent_parameters.id_cao_data_main = this.id_cao_data_main;

                    let schedule = JSON.parse(res.data.schedule_data.schedule)
                    for(let i=1;i<=7;i++){
                        schedule[i].forEach(item => {
                            item.percent_value = {name: 100 + parseInt(item.percent_value) + '%', value: item.percent_value};
                        });
                        this.ort[i] = schedule[i];
                        this.acceptedTime(i);
                    }
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.prepareDate()
                });
        },   

        prepareDate(){
            if(this.cao_periods[0].name.length > 10){
                this.cao_periods[0].name = this.cao_periods[0].name.slice(0, 10)
            }
            this.disabledDatesRange = '(date < new Date('+moment(this.cao_periods[0].name,"DD-MM-YYYY").add(1, 'days')+'))';
            this.disabledDatesRangeTo = '(date < new Date('+moment(this.cao_periods[0].name,"DD-MM-YYYY").add(1, 'days')+'))';
            this.date_from = moment(this.cao_periods[0].name,"DD-MM-YYYY").add(1, 'days').format("YYYY-WW");
            this.refreshAll();
            this.refresh++;
            this.loading = false;
        },

        acceptedTime(day){    
            this.ort[day].forEach((element,index) => {
                if(element.time_to != null){
                    if(element.time_to == '00:00'){
                        if(day == 1) this.acceptedTimeMaan[index] = '< 23:59';
                        else if(day == 2) this.acceptedTimeDin[index] = '< 23:59';
                        else if(day == 3) this.acceptedTimeWoen[index] = '< 23:59';
                        else if(day == 4) this.acceptedTimeDond[index] = '< 23:59';
                        else if(day == 5) this.acceptedTimeVrij[index] = '< 23:59';
                        else if(day == 6) this.acceptedTimeZat[index] = '< 23:59';
                        else if(day == 7) this.acceptedTimeZon[index] = '< 23:59';
                    }else{
                        if(day == 1) this.acceptedTimeMaan[index] = '>' + element.time_to;
                        else if(day == 2) this.acceptedTimeDin[index] = '>' + element.time_to;
                        else if(day == 3) this.acceptedTimeWoen[index] = '>' + element.time_to;
                        else if(day == 4) this.acceptedTimeDond[index] = '>' + element.time_to;
                        else if(day == 5) this.acceptedTimeVrij[index] = '>' + element.time_to;
                        else if(day == 6) this.acceptedTimeZat[index] = '>' + element.time_to;
                        else if(day == 7) this.acceptedTimeZon[index] = '>' + element.time_to;
                    }
                }else{
                    if(day == 1) this.acceptedTimeMaan[index] = null;
                    else if(day == 2) this.acceptedTimeDin[index] = null;
                    else if(day == 3) this.acceptedTimeWoen[index] = null;
                    else if(day == 4) this.acceptedTimeDond[index] = null;
                    else if(day == 5) this.acceptedTimeVrij[index] = null;
                    else if(day == 6) this.acceptedTimeZat[index] = null;
                    else if(day == 7) this.acceptedTimeZon[index] = null;
                }
            });
        },

        getPercentValues(event){
            this.percent_values = event;
        },

        refreshAll(){
            for(let i=0;i<=7;i++){
                this.refreshTime[i]++;
            }
        },

        refreshTimes(type){
            this.refreshTime[type]++;
        },
        
        add(type){
            if(this.ort[type][this.ort[type].length -1].time_from  && this.ort[type][this.ort[type].length -1].time_to && this.ort[type][this.ort[type].length -1].time_to != '00:00' && this.ort[type][this.ort[type].length -1].time_to != '24:00'){
                this.ort[type].push({time_from:null,time_to:null,percent_value:null});
                this.acceptedTime(type)
                this.refreshTime[type]++;
            }
        },

        remove(type, index){
            this.ort[type].splice(index, 1);
            this.acceptedTime(type)
            this.refreshTime[type]++;
        },
    },
 
};
</script>
<style scoped>
.app-local-line {
    width:95%; 
    border-top: 1px solid #e6e6e6 !important;  
    margin-top:10px !important ;
    margin-bottom: 10px !important;   
    margin-left: 15px;
}
.app-local-headers-row{
    margin-left: 0px;
    width: 94%;
}
.app-local-headers{
    text-align: left;
    padding:0px !important;
}
.app-day-label{
    font-size: 14px;
    font-weight: 600;
    color: #174066;
}

.app-local-button{
    opacity: 1 !important;
    border: solid 1px #D0D5DD !important;
    border-radius: 0.25rem !important;
    line-height: 1rem !important;
    font-size:  1rem !important;
    color: #066791 !important;
    max-height: 31.3px !important;
    min-height: 31.3px !important;
}
.app-local-button:hover{
    border-color: #066791 !important;
    box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1);
}

.app-local-button:focus{
    border-color: #066791 !important;
    box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1);
}

.app-local-button-disabled:hover, .app-local-button-disabled:focus{
    border: solid 1px #D0D5DD !important;
    box-shadow: none !important;
}

.app-local-btn-cancel{
    color: rgba(0, 0, 0, 0.5) !important;
    opacity:0.5;
    cursor: auto;
}
.app-local-btn-cancel:hover{
    color: rgba(0, 0, 0, 0.5) !important;
    opacity:0.5;
    cursor: auto;
}
</style>